<template>
  <div>
    <div class="search-input mx-5">
      <el-autocomplete
        v-model="searchTerm"
        :fetch-suggestions="fetchSuggestions"
        :trigger-on-focus="false"
        :debounce="700"
        :popper-append-to-body="false"
        :placeholder="$t('commons.searchWithDots')"
        prefix-icon="el-icon-search"
        class="autocomplete"
        @keydown.enter.stop
        @select="followLink"
      >
        <template #default="{ item: { item } }">
          {{ item.name }}
          <small class="text-muted">
            / {{ $t(`routes.${item.route}`) }}
            <template v-if="item.subRoute">/ {{ item.subRoute }}</template>
          </small>
        </template>
      </el-autocomplete>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue';

import { useTenancy } from '@/modules/auth';

import { useSearch } from '../compositions/useSearch';
import { getLinks, defaultSearchResults } from '../compositions/useFuzzySearch';

import { useSelectedItem } from '../../core/compositions/selected-item';

export default {
  setup() {
    const callbackRef = ref(null);
    const searchTerm = ref('');
    const queryStringRef = ref('');

    const { currentTenant } = useTenancy();
    const { followLink } = useSelectedItem();

    const searchQueryParams = computed(() => ({
      businessId: currentTenant.value.id,
      query: queryStringRef.value,
      fromDate: new Date().setFullYear(new Date().getFullYear() - 1),
      toDate: new Date(),
    }));

    const { onResult } = useSearch(searchQueryParams);

    onResult(({ data }) => {
      const links = getLinks(data.search);
      const searchResult = links.search(queryStringRef.value);
      callbackRef.value(searchResult);
    });

    watch(
      currentTenant,
      () => {
        searchTerm.value = '';
        queryStringRef.value = '';
        if (callbackRef.value) callbackRef.value([]);
      },
      { immediate: true, deep: true }
    );

    const fetchSuggestions = (queryString, callback) => {
      queryStringRef.value = '';
      const trimmedString = queryString.trim();

      callbackRef.value = callback;
      queryStringRef.value = trimmedString;

      if (!trimmedString) {
        const links = getLinks(defaultSearchResults);
        return callback(links.search(trimmedString));
      }
    };

    return {
      searchTerm,
      followLink,
      fetchSuggestions,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.search-input {
  &:focus-within .autocomplete {
    width: 650px;
  }

  .autocomplete {
    width: 250px;
    transition: width 200ms ease-in;
    ::v-deep input {
      border: none;
      border-radius: 5px;

      &::placeholder {
        font-weight: 300;
        color: #9295a5;
      }
    }

    ::v-deep .el-autocomplete-suggestion {
      width: 100% !important;
    }
  }
}
</style>

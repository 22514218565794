import Fuse from 'fuse.js';

const fuseOptions = {
  shouldSort: true,
  threshold: 0.2,
  keys: ['name'],
};

export const defaultSearchResults = {
  products: [],
  suppliers: [],
  suppliersProductRef: [],
};

export const getLinks = (searchResults) => {
  const links = [];
  searchResults.suppliers.forEach((supplier) => {
    links.push(
      {
        obj: 'supplier',
        name: supplier.name,
        route: 'suppliersPurchaseManagement',
        location: { name: 'supplierProductsPurchaseManagement', params: { supplierId: supplier.id } },
      },
      {
        obj: 'supplier',
        name: supplier.name,
        route: 'documents',
        location: { name: 'documents', query: { supplierId: supplier.id } },
      }
    );
  });

  searchResults.products.forEach((product) => {
    const supplier = searchResults.suppliersProductRef.find((s) => s.id === product.businessId);
    links.push({
      obj: 'item',
      name: product.name,
      route: 'suppliersPurchaseManagement',
      subRoute: supplier.name,
      location: { name: 'itemTerms', params: { supplierId: product.businessId, itemId: product.id } },
    });
  });
  return new Fuse(links, fuseOptions);
};

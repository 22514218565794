import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';

import { useNotification } from '@/modules/core/compositions/notification';

export const useSearch = (variables) => {
  const { error } = useNotification();
  const { result, loading, onError, onResult } = useQuery(SEARCH_QUERY, variables, () => ({
    enabled: !!variables.value.query,
  }));

  onError((err) => {
    console.error('useSearch', err);
    error();
  });

  const results = computed(() => result?.value || {});

  return {
    results,
    loading,
    onResult,
  };
};

const SEARCH_QUERY = gql`
  query search($businessId: ID!, $query: String!, $fromDate: Date, $toDate: Date) {
    search(businessId: $businessId, query: $query, fromDate: $fromDate, toDate: $toDate) {
      products {
        id
        name
        businessId
      }
      suppliers {
        id
        name
      }
      suppliersProductRef {
        id
        name
      }
    }
  }
`;

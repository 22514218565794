<template>
  <el-dropdown
    trigger="click"
    class="d-flex"
    :disabled="isMoreThanOneTenant"
    @visible-change="toggleMenu"
    @command="navigateToTenant"
  >
    <div class="current-tenant">
      <a
        :disabled="isMoreThanOneTenant"
        class="restaurant-name text-typography-primary d-flex px-2 align-items-center justify-content-between"
        :class="{ active: menuOpened, 'dropdown-active': !isMoreThanOneTenant }"
      >
        <span v-if="currentTenant" class="d-flex align-items-center gap-2 tenant">
          <div
            class="background-circle d-flex align-items-center justify-content-center rounded-pill"
            style="height: 32px; min-width: 32px"
          >
            <template v-if="business.logoUrlSigned">
              <img :src="`${business.logoUrlSigned}`" alt="logo" width="32" height="32" />
            </template>
            <BusinessIcon v-else :size="20" class="default-business-logo" />
          </div>
          <TruncatedText class="truncated">{{ currentTenant.name }}</TruncatedText>
        </span>
        <ChevronUpDownIcon v-if="!isMoreThanOneTenant" :size="16" name="arrow-down" class="chevron" />
      </a>
    </div>
    <el-dropdown-menu slot="dropdown" class="restaurants-menu m-0 mt-2">
      <div class="search-tenant">
        <el-input
          v-if="shouldShowSearch"
          ref="searchBar"
          :value="query"
          prefix-icon="el-icon-search"
          :placeholder="`${$t('restaurant / supplier name')}...`"
          placement="top"
          @input="onSearch"
          @keydown.native.enter="selectIfOne"
        >
          <div slot="suffix" class="clear-icon" :class="{ 'show-clear': query !== null }">
            <a class="clear-button" @click="onSearch(null)">
              <RemoveIcon />
            </a>
          </div>
        </el-input>
      </div>
      <div class="business-list overflow-auto">
        <template v-if="loading">
          <el-dropdown-item v-for="n in AMOUNT_FOR_LOADING" :key="`dummy-item-${n}`">
            <DropdownItemLoadingSkeleton />
          </el-dropdown-item>
        </template>
        <template v-else>
          <template v-if="tenantsList.length === 0">
            <div class="no-data">
              {{ $t('noData') }}
            </div>
          </template>
          <template v-else>
            <el-dropdown-item v-for="tenant in tenantsList" :key="tenant.id" :command="tenant">
              <TenantItem
                :name="tenant.name"
                :address="tenant.contact?.address"
                :capabilities="tenant.capabilities"
                :logo-url="tenant.logoUrl"
                :selected="tenant.id === currentTenant.id"
                :token="token"
              />
            </el-dropdown-item>
          </template>
        </template>
      </div>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue';
import { clone } from 'ramda';

import { useTenancy } from '@/modules/auth';
import { BusinessIcon, ChevronUpDownIcon, RemoveIcon } from '@/assets/icons';
import { useTenants } from '../../core/compositions/tenants';
import { useUnreadNotificationCountByBusiness } from '../../core/compositions/notifications/useUnreadNotificationCountByBusiness';
import { useBusinessById } from '@/modules/business/compositions/business';
import { DropdownItemLoadingSkeleton, TruncatedText } from '@/modules/core';
import TenantItem from './TenantItem';

const TENANT_TYPES = {
  serviceProvider: 'SERVICE_PROVIDER',
  restaurant: 'RESTAURANT',
  supplier: 'SUPPLIER',
  bookKeepers: 'BOOK_KEEPING',
};

const AMOUNT_FOR_LOADING = 5;
const MIN_AMOUNT_OF_TENANTS_FOR_SEARCHBAR = 10;

export default {
  name: 'RestaurantToggle',
  components: { ChevronUpDownIcon, TenantItem, DropdownItemLoadingSkeleton, RemoveIcon, BusinessIcon, TruncatedText },
  setup() {
    const { $router } = getCurrentInstance().proxy;
    const { currentTenant, token } = useTenancy();
    const query = ref(null);
    const searchBar = ref(null);
    const defaultTenants = ref([]);
    const menuOpened = ref(false);
    const initialLoad = ref(true);
    const hasMoreThanOneTenant = ref(false);

    const { tenants, loading } = useTenants(computed(() => ({ search: query.value })));
    const { business } = useBusinessById(computed(() => ({ businessId: currentTenant?.value?.id })));
    const tenantsSource = ref([]);

    const isMoreThanOneTenant = computed(() => {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      tenantsSource.value = tenants.value;
      if (initialLoad.value && tenants.value.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        initialLoad.value = false;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        hasMoreThanOneTenant.value = tenants.value.length > 1;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        defaultTenants.value = clone(tenants.value);
      }
      return !hasMoreThanOneTenant.value;
    });

    const tenantsList = computed(() => {
      const clonedTenants = clone(tenantsSource.value);
      return clonedTenants.sort((tenant1, tenant2) => tenant1.name.localeCompare(tenant2.name));
    });

    const shouldShowSearch = computed(() => {
      if (!menuOpened.value) return false;
      if (defaultTenants.value.length >= MIN_AMOUNT_OF_TENANTS_FOR_SEARCHBAR) {
        if (searchBar.value) {
          searchBar.value.focus();
        }

        return true;
      }
      return false;
    });

    const { unreadNotificationCountByBusiness } = useUnreadNotificationCountByBusiness();

    const navigateToTenant = (tenant) => {
      if (tenant.type === TENANT_TYPES.serviceProvider) {
        $router.push({ name: 'clarity', params: { tenantId: tenant.id } });
      } else {
        $router.push({ name: 'tenant', params: { tenantId: tenant.id } });
      }
    };

    const onSearch = (text) => {
      if (text === '') query.value = null;
      else query.value = text;
    };
    const selectIfOne = () => {
      if (tenantsList.value.restaurants.length === 1) {
        navigateToTenant(tenantsList.value.restaurants[0]);
      }
    };

    const toggleMenu = (isOpen) => {
      menuOpened.value = isOpen;
      if (isOpen) {
        query.value = null;
        tenantsSource.value = defaultTenants.value;
      }
    };

    return {
      AMOUNT_FOR_LOADING,
      business,
      searchBar,
      currentTenant,
      token,
      loading,
      tenantsList,
      query,
      isMoreThanOneTenant,
      menuOpened,
      shouldShowSearch,
      onSearch,
      toggleMenu,
      selectIfOne,
      navigateToTenant,
      unseenNotificationCountByBusiness: computed(() =>
        unreadNotificationCountByBusiness.value.reduce(
          (acc, currentBusinessCount) => ({
            ...acc,
            [currentBusinessCount.businessId]: currentBusinessCount.unseenNotificationCount,
          }),
          {}
        )
      ),
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

::v-deep {
  .el-input__inner {
    border: none !important;
  }
  .el-dropdown-menu__item {
    &:hover {
      background-color: transparent;
    }
    padding: 0;
  }
  .tenant {
    div.truncated {
      width: 11rem;
      p {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.25rem;
      }
    }
  }
}

$menu-side-padding: 1.25rem;
$list-menu-width: $navigation-menu-width-expanded * 1.5;

.restaurants-menu {
  width: $list-menu-width;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.business-list {
  max-height: 45vh;

  .no-data {
    display: flex;
    margin-top: 40px;
    color: $typography-secondary;
    flex-direction: column;
    align-items: center;
    height: 35vh;
  }
}

.current-tenant {
  width: $navigation-menu-width-expanded;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .restaurant-name {
    align-self: center;
    border-radius: 0.25rem;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    padding: 0.25rem;
    width: 100%;
    height: 48px;
  }
  .active {
    background-color: #f3f3f4;
  }
  .dropdown-active {
    &:hover {
      background-color: #f3f3f4;
      .chevron {
        color: $typography-primary;
      }
    }
  }
}
.search-tenant {
  border-bottom: 1px solid $outline;
  height: 48px;
  padding-inline-start: 0.75rem;
  ::placeholder {
    font-family: Rubik;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }
  .show-clear {
    display: flex !important;
  }
  .clear-icon {
    display: none;
    height: 100%;
    cursor: pointer;
    margin-inline-end: 5px;

    .clear-button {
      align-self: center;
      color: $typography-secondary;
    }
  }
}
.chevron {
  color: $typography-secondary;
}
.background-circle {
  background-color: #fbfbfb;
  border: 1px solid #f3f3f4;
}
.default-business-logo {
  color: #bbbec2;
}
</style>

<template>
  <div class="tenant-item">
    <div class="business">
      <div class="business-icon">
        <img v-if="logoUrl" :src="`${logoUrl}&token=${token}`" width="24" height="24" />
        <BusinessIcon v-else class="default-icon" :size="16" />
      </div>
      <div class="business-info">
        <div class="name">
          <p class="truncated text-truncate">{{ name ?? MISSING_DATA_TEXT }}</p>
        </div>
        <div class="address">
          <p class="truncated text-truncate">{{ address ?? MISSING_DATA_TEXT }}</p>
        </div>
      </div>
    </div>
    <div v-if="selected" class="selected">
      <CheckIcon :size="20" />
    </div>
    <div v-else class="capabilities" :set="(iconNames = getIconNamesByCapabilities(capabilities))">
      <div v-for="(iconName, index) in iconNames" :key="`${iconName}${index}`">
        <component :is="iconName" :size="16" />
      </div>
    </div>
  </div>
</template>
<script>
import { RestaurantIcon, SupplierNewIcon, BookkeepingIcon, BusinessIcon, ClarityIcon, CheckIcon } from '@/assets/icons';
import { BUSINESS_TYPES_NORMALIZED } from '@/modules/permissions/pages/BusinessRelations';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';

export default {
  components: { RestaurantIcon, SupplierNewIcon, BookkeepingIcon, BusinessIcon, ClarityIcon, CheckIcon },
  props: {
    name: { type: String, default: null },
    address: { type: String, default: null },
    capabilities: { type: Object, required: true },
    logoUrl: { type: String, default: null },
    token: { type: String, default: null },
    selected: { type: Boolean, required: true },
  },
  setup() {
    const getIconNamesByCapabilities = (capabilities) => {
      const iconNames = [];
      for (const [capabilityName, capabilityStatus] of Object.entries(capabilities)) {
        if (capabilityStatus === true) {
          switch (capabilityName) {
            case BUSINESS_TYPES_NORMALIZED.RESTAURANT:
              iconNames.push('RestaurantIcon');
              break;
            case BUSINESS_TYPES_NORMALIZED.SUPPLIER:
              iconNames.push('SupplierNewIcon');
              break;
            case BUSINESS_TYPES_NORMALIZED.BOOK_KEEPING:
              iconNames.push('BookkeepingIcon');
              break;
            case BUSINESS_TYPES_NORMALIZED.SERVICE_PROVIDER:
              iconNames.push('ClarityIcon');
              break;
            default:
              return null;
          }
        }
      }

      return iconNames;
    };

    return {
      MISSING_DATA_TEXT,
      getIconNamesByCapabilities,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
.tenant-item {
  display: flex;
  height: 3.75rem;
  align-items: center;
  padding: 0.75rem 1rem;
  justify-content: space-between;
  gap: 0.5rem;
  &:hover {
    cursor: pointer;
    background-color: #f3f3f4;
  }
  .business {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .business-icon {
      width: 2rem;
      height: 2rem;
      border-radius: 50rem;
      background-color: #fbfbfb;
      border: 1px solid #f3f3f4;
      margin-right: 0.25rem;
      margin-left: 0.25rem;
      align-items: center;
      display: flex;
      justify-content: center;

      .default-icon {
        color: #bbbec2;
      }
    }
    .business-info {
      display: flex;
      flex-direction: column;
      width: 280px;
      .name {
        color: $typography-primary;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.25rem;
      }
      .address {
        color: $typography-secondary;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1rem;
      }
    }
  }
  .capabilities {
    display: flex;
    gap: 0.25rem;
    color: #bbbec2;
  }

  .selected {
    color: $typography-primary;
  }
}
.truncated {
  width: 100%;
}
</style>
